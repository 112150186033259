/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState } from 'react';
import Airtable from "airtable";
import { addMonths } from 'date-fns';
import Slider from 'rc-slider';
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import L, { Icon } from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { format, getMonth, parseISO } from "date-fns";
import {
  RiTwitterFill,
  RiYoutubeFill,
  RiMastodonFill
} from "react-icons/ri"

import 'leaflet/dist/leaflet.css';
import 'rc-slider/assets/index.css';
import './App.css';

const apiKey = process.env.GATSBY_AIRTABLE_API_KEY;
const baseID = "appgjrn7ih3I52FNK";

const base = new Airtable({ apiKey: apiKey }).base(baseID);

const Map = () => {
  const [records, setRecords] = useState([]);
  const [lowerBound, setLowerBound] = useState(0);
  const [upperBound, setUpperBound] = useState(11);

  useEffect(() => {
    base("Conferences")
      .select({ view: "VerifiedUpcomingConfs" })
      .eachPage((records, fetchNextPage) => {
        setRecords(records);
        fetchNextPage();
      })
  }, []);

  function handleSliderChange(values) {
    setLowerBound(values[0]);
    setUpperBound(values[1]);
  }

  const today = new Date();
  const marks = [];

  for (var i = 0; i < 12; i++) {
    const future = addMonths(today, i);
    marks[i] = future.toLocaleString('default', { month: 'short' }) + " '" +
           future.toLocaleString('default', { year: '2-digit' });
  }
  
  return (
    <Layout>
      <Seo title="Infosec Conference Map" image="/assets/infosec-map-preview.png"
        description="A visual guide to upcoming infosec and hacker conferences around the world."
      />
      <div className="App">
        <div className="conference-table-title">Upcoming Infosec Conferences</div>
        <div className="conference-table-subtitle">Want to add a conference? <a href="https://airtable.com/shrg8Xv2kekv1MrY0">Submit it here.</a> If you spot an error, please <a href="https://twitter.com/LightfootJaime">DM</a> or <a href="mailto:jaime@lightfootlabs.io">email</a> me.</div>
        <div className="map-controls">
          <Slider range min={0} max={11} step={1} defaultValue={[0,11]} value={[lowerBound,upperBound]} onChange={handleSliderChange} allowCross={false} marks={marks} dots/>
        </div>
        <MapView confs={records} range={[lowerBound, upperBound]}/>
        <TableView confs={records} range={[lowerBound, upperBound]}/>
        <div className="conf-info-blurb">
          <hr/>
          <p>Infosec conferences can be a great way to learn and further your career. In addition to talks, workshops, contests, and other events, there's also "hallway con", the opportunity for interesting conversations outside of the conference schedule (and hall). If you are unable to travel, look for virtual events and/or watch talks that are often uploaded to Youtube after the conference.</p>
        </div>
      </div>
    </Layout>
  )
}


const TableView = (props) => {
  const { confs, range } = props;
  const lowerBound = range[0];
  const upperBound = range[1];

  var conferences = confs.map((p) => mapConfRecord(p));

  conferences = conferences.filter(c => filterConferenceByDateRange(c, lowerBound, upperBound));

  return (
    <div className="table-container">
       {conferences && conferences.map((conf, count) => (
          <div className="conference-table-row" key={count}>
            <div className="conference-table-row-conf-header">
              <div className="conference-table-row-conf-name">
                <a href={conf.website} className="title">{conf.name}</a>
                {conf.twitter && <a href={conf.twitter} className="conf-social-icon" aria-label="Twitter link for conference"><RiTwitterFill /></a>}
                {conf.mastodon && <a href={conf.mastodon} className="conf-social-icon" aria-label="Mastodon link for conference"><RiMastodonFill /></a>}
                {conf.youtube && <a href={conf.youtube} className="conf-social-icon" aria-label="Youtube link for conference"><RiYoutubeFill /></a>}
              </div>
              <div className="conference-time-location">
                <div>{dateShorthandParser(conf.startDate)}  - {dateShorthandParser(conf.endDate)}</div>
                
                {conf.virtual ? (
                  <div className="virtual-location">&nbsp;Virtual Event</div>
                ) : (
                  <div><span>&nbsp;in&nbsp;</span>{conf.city}, {conf.state}</div>
                )}
                
              </div>
            </div>
            <div className="conference-table-row-conf-details">
              <div className="conf-blurb">{conf.blurb}</div>
              <div>{conf.online && " Online available"}</div>
            </div>
          </div>
       ))}
       {conferences.length === 0 && (
        <div className="no-results">No results found</div>
       )}
    </div>
  );
};

const MapView = (props) => {
  const { confs, range } = props;
  const lowerBound = range[0];
  const upperBound = range[1];

  var conferences = confs.map((p) => mapConfRecord(p));

  conferences = conferences.filter(c => filterConferenceByDateRange(c, lowerBound, upperBound));

  conferences = conferences.filter(c => c.virtual !== true);

  return (
    <div className="map-container">
      <MapContainer center={[22,10]} maxBounds={[[-90, -180], [90, 180]]} zoom={2} >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
         />
         {conferences.map((conf, count) => (
           <ConfMarker key={count} conf={conf}/>
         ))}
      </MapContainer>
      {/* Pin icon from Freepik at https://www.flaticon.com/free-icons/pin */}
    </div>
  );
};

const ConfMarker = (props) => {
  const conf = props.conf;

  let customIcon;
  if (typeof window !== 'undefined') {
    customIcon = new Icon({
      iconUrl: '/assets/map-flag.png',
      shadowUrl: '/assets/icon-shadow.png',
      iconSize: [46, 46],
      iconAnchor: [23, 46] // https://gis.stackexchange.com/questions/324816/react-leaflet-marker-has-wrong-position-after-zoom-out
    });
    L.Marker.prototype.options.icon = customIcon;
  }

  var description = "No info given for conference"

  if (conf.blurb) {
    description = conf.name + ": " + conf.blurb;
  }

  return (
    <Marker position={[conf.latitude, conf.longitude]}  icon={(!!customIcon) ? customIcon : null}>
     <Popup>
       {description}
     </Popup>
   </Marker>
  )
}

function mapConfRecord(rec) {
  var newConf = {
    name: rec.fields['Name'],
    city: rec.fields['City'],
    state: rec.fields['State'],
    country: rec.fields['Country'],
    latitude: rec.fields['Latitude'],
    longitude: rec.fields['Longitude'],
    startDate: rec.fields['Start Date'],
    endDate: rec.fields['End Date'],
    twitter: rec.fields['Twitter'],
    mastodon: rec.fields['Mastodon'],
    youtube: rec.fields['Youtube'],
    website: rec.fields['Website'], 
    blurb: rec.fields['Blurb'],
    online: rec.fields['Online Option?'],
    virtual: rec.fields['Virtual']
  };

  return newConf;
}

function filterConferenceByDateRange(c, lowerBound, upperBound) {
  const startDateMonth = getMonth(parseISO(c.startDate.toString()));
  const endDateMonth = getMonth(parseISO(c.startDate.toString()));

  const startDateAfterLowerBound = lowerBound <= startDateMonth;
  const endDateBeforeUpperBound = endDateMonth <= upperBound;

  if (startDateAfterLowerBound && endDateBeforeUpperBound) {
    return c;
  } else {
    return null;
  }
}

function dateShorthandParser(d)  {
  return format(parseISO(d.toString()), 'M/dd')
}

export default Map
